<template>
  <div style="text-align: left">
    <el-card
      shadow="never"
      style="margin-bottom: 25px"
      :body-style="{ padding: '20px' }"
    >
      <div slot="header" class="my-header">
        <div>分类管理</div>
        <div>
          <el-button
            @click="addClassify"
            icon="el-icon-circle-plus"
            type="primary"
            size="mini"
          >
            添加分类
          </el-button>
          <!-- <el-button icon="el-icon-delete" type="danger" size="mini">全部删除</el-button> -->
          <el-button size="mini" @click="hasClassifyDatas = !hasClassifyDatas">
            {{ hasClassifyDatas ? '收起' : '展开' }}
            <i
              class="el-icon-caret-top el-icon--right"
              :class="[
                hasClassifyDatas ? 'el-icon-caret-top' : 'el-icon-caret-bottom',
              ]"
            ></i>
          </el-button>
        </div>
      </div>
      <div v-show="hasClassifyDatas">
        <div class="classifyDatasListItems">
          <div
            v-for="(v, i) of classifyDatasList"
            class="classifyDatasListItem"
            :key="i"
          >
            <div class="classifyDatasListLeft">{{ v.text }}</div>
            <div class="classifyDatasListRight">
              <el-button-group
                class="m-l-15 m-b-15"
                v-for="(vv, i) of v.list"
                :key="i"
                :title="'创建时间：' + vv.classifyCreatetime"
              >
                <el-button type="" size="mini">{{ vv.classifyName }}</el-button>
                <el-button
                  type=""
                  size="mini"
                  @click="editClassify(vv)"
                  icon="el-icon-edit-outline"
                ></el-button>
                <el-button
                  type=""
                  size="mini"
                  @click="delMessageBox('deleteClassify', vv)"
                  icon="el-icon-delete"
                ></el-button>
              </el-button-group>
            </div>
          </div>
        </div>
        <el-table :data="classifyDatas" style="width: 100%" v-if="0">
          <el-table-column label="归类" width="130">
            <template slot-scope="scope">
              <template v-if="scope.row.classifyCategory == 0">
                <i class="el-icon-wallet"></i>
                <span style="margin-left: 10px">家具类</span>
              </template>
              <template v-else>
                <i class="el-icon-brush"></i>
                <span style="margin-left: 10px">材料类</span>
              </template>
            </template>
          </el-table-column>
          <el-table-column label="创建时间" width="220">
            <template slot-scope="scope">
              <i class="el-icon-time"></i>
              <span style="margin-left: 10px">
                {{ scope.row.classifyCreatetime }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="分类名称" width="280">
            <template slot-scope="scope">
              {{ scope.row.classifyName }}
            </template>
          </el-table-column>
          <el-table-column label="操作" align="right">
            <template slot-scope="scope">
              <el-button size="mini" @click="editClassify(scope.row)">
                修改
              </el-button>
              <el-button
                size="mini"
                @click="delMessageBox('deleteClassify', scope.row)"
                type="danger"
                plain
              >
                删除
              </el-button>
              <!-- <el-button size="mini">下架</el-button> -->
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>
    <el-card shadow="never" style="margin-bottom: 25px">
      <div slot="header" class="my-header">
        <div>品牌管理</div>
        <div>
          <el-button
            @click="$oucy.go('/enter/addbrand')"
            icon="el-icon-circle-plus"
            type="primary"
            size="mini"
          >
            添加品牌
          </el-button>
          <!-- <el-button icon="el-icon-delete" type="danger" size="mini">全部删除</el-button> -->
          <el-button size="mini" @click="hasBrandList = !hasBrandList">
            {{ hasBrandList ? '收起' : '展开' }}
            <i
              class="el-icon-caret-top el-icon--right"
              :class="[
                hasBrandList ? 'el-icon-caret-top' : 'el-icon-caret-bottom',
              ]"
            ></i>
          </el-button>
        </div>
      </div>
      <div class="brand-flex-container" v-show="hasBrandList">
        <template v-if="brandList">
          <div
            v-for="(item, index) in brandList"
            :key="item.id"
            class="brand-container"
          >
            <el-card shadow="hover">
              <div class="brand-container-top">
                <img :src="$oucy.ossUrl + item.brandIcon" />
                <div>{{ item.brandName }}</div>
                <div class="brand-container-top-txt">{{ item.brandDesc }}</div>
                <div class="brand-container-top-txt">
                  {{ item.brandCategory == 0 ? '家具类' : '材料类' }}
                </div>
              </div>
              <div class="brand-container-button">
                <el-button
                  @click="changeBrand(item.id)"
                  type="text"
                  class="button"
                >
                  修改
                </el-button>
                <el-button
                  @click="
                    delMessageBox('delFurnitureGlobalBrand', item, null, index)
                  "
                  type="text"
                  class="button"
                >
                  删除
                </el-button>
              </div>
            </el-card>
            <template
              v-if="
                item.brandAuditStatus === $oucy.brandAuditStatusEnum.APPLYING
              "
            >
              <img
                src="../../../assets/brand/brand_success.jpg"
                class="brand-status"
              />
            </template>
            <template
              v-else-if="
                item.brandAuditStatus === $oucy.brandAuditStatusEnum.APPLY_FAIL
              "
            >
              <img
                src="../../../assets/brand/brand_fail.jpg"
                class="brand-status"
              />
            </template>
          </div>
        </template>
      </div>
    </el-card>
    <el-card shadow="never" style="margin-bottom: 25px">
      <div slot="header" class="my-header">
        <div>
          产品列表
          <el-input
            v-model="spuName"
            placeholder="请输入关键字搜索"
            size="mini"
            class="m-l-10"
            style="width: 150px"
          ></el-input>
          <el-button type="default" size="mini" @click="search">搜索</el-button>
        </div>
        <div>
          <el-button
            type="default"
            size="mini"
            @click="distributionVisible = true"
          >
            一键导入第三方平台产品
          </el-button>

          <!-- <el-button type="default" size="mini">导出列表</el-button> -->
          <!-- <el-button type="default" size="mini" @click="distributionVisible=true">一键铺货</el-button> -->
          <el-button
            @click="$oucy.go('/enter/publish/good')"
            icon="el-icon-circle-plus"
            type="primary"
            size="mini"
          >
            发布产品
          </el-button>
          <!-- <el-button icon="el-icon-delete" type="danger" size="mini">全部删除</el-button> -->
          <!-- <el-button size="mini">收起<i class="el-icon-caret-top el-icon--right"></i></el-button> -->
          <el-button size="mini" @click="hasList = !hasList">
            {{ hasList ? '收起' : '展开' }}
            <i
              class="el-icon-caret-top el-icon--right"
              :class="[hasList ? 'el-icon-caret-top' : 'el-icon-caret-bottom']"
            ></i>
          </el-button>
        </div>
      </div>
      <div v-show="hasList">
        <template>
          <el-table
            :data="prlductData.content"
            style="width: 100%"
            ref="productListTable"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column type="index" label="编号"></el-table-column>
            <el-table-column prop="date" label="产品封面" width="100">
              <template slot-scope="scope">
                <!-- <video v-if="scope.row.spuVideo" :src="$oucy.ossUrl+scope.row.spuVideo" style="width:40px;height: 40px;background: #f1f1f1;" controls="1"></video> -->
                <!-- <div v-else> -->
                <el-avatar
                  shape="square"
                  :src="scope.row.cover"
                  v-if="hasImportImg(scope.row.cover)"
                ></el-avatar>
                <el-avatar
                  shape="square"
                  :src="$oucy.ossUrl + scope.row.cover"
                  v-else
                ></el-avatar>
                <!-- </div> -->
              </template>
            </el-table-column>
            <el-table-column prop="spuName" label="产品名称/所属品牌">
              <template slot-scope="scope">
                <div
                  class="pointer line-1"
                  :title="scope.row.spuName"
                  @click="openNewPage(scope.row)"
                >
                  {{ scope.row.spuName }}
                </div>
                <div
                  class="c-2"
                  v-if="scope.row.brand && scope.row.brand.brandName"
                >
                  {{ scope.row.brand.brandName }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="brand.brandName" label="所属分类">
              <template slot-scope="scope">
                <el-select
                  size="mini"
                  v-model="scope.row.classify.id"
                  placeholder="请选择"
                  v-if="
                    scope.row.classify && scope.row.classify.id && classifyDatas
                  "
                  @change="updateSpuClass(scope.row)"
                >
                  <el-option
                    v-for="item in classifyDatas"
                    :key="item.id"
                    :label="item.classifyName"
                    :value="item.id"
                  >
                    ({{ item.classifyCategory == 0 ? '家具类' : '材料类' }}){{
                      item.classifyName
                    }}
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column width="80" prop="spuRankingWeight" label="排序">
              <template slot-scope="scope">
                <el-input
                  v-model.number="scope.row.spuRankingWeight"
                  size="mini"
                  placeholder="请输入排序"
                  @blur="blurSpuRankingWeight(scope.row)"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="quantity" width="80" label="库存">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.quantity"
                  size="mini"
                  :disabled="scope.row.sku && scope.row.sku.length > 1"
                  placeholder="请输入库存"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              width="80"
              prop="spuSoldAmount"
              label="销量"
            ></el-table-column>
            <el-table-column prop="spuPrise" label="当前价格">
              <template slot-scope="scope">
                <span class="spuPrise" v-if="scope.row.spuPrise == '0.00'">
                  面议
                </span>
                <span class="spuPrise" v-else>￥{{ scope.row.spuPrise }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="spuPrise" label="会员价格">
              <template slot-scope="scope">
                <span
                  class="spuPrise"
                  v-if="scope.row.spuMemberSinglePrise == '0.00'"
                >
                  面议
                </span>
                <span class="spuPrise" v-else>
                  ￥{{ scope.row.spuMemberSinglePrise }}
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="isShelves" label="申请日期/状态">
              <template slot-scope="scope">
                <div>{{ scope.row.spuCreatetime }}</div>
                <span v-if="scope.row.isShelves" class="putAway">已上架</span>
                <span v-else class="soldOut">已下架</span>
              </template>
            </el-table-column>
            <!-- 审核状态 1待审核 2审核通过 3审核驳回 -->
            <el-table-column prop="spuAuditStatus" label="审核状态">
              <template slot-scope="scope">
                <span v-if="scope.row.spuAuditStatus == 1" class="soldOut">
                  待审核
                </span>
                <span v-else-if="scope.row.spuAuditStatus == 2" class="putAway">
                  审核通过
                </span>
                <span v-else-if="scope.row.spuAuditStatus == 3" class="soldOut">
                  审核驳回
                </span>
              </template>
            </el-table-column>
            <el-table-column width="240" label="操作">
              <template slot-scope="scope">
                <!-- <el-button type="default" size="mini">报名秒杀</el-button> -->
                <!-- <el-button type="default" size="mini">报名特价</el-button> -->
                <el-button
                  type="danger"
                  size="mini"
                  @click="deleteFurnitureSpuById(scope.row, scope.index)"
                  :disabled="scope.row.isShelves"
                  :title="scope.row.isShelves ? '如需删除请先下架' : ''"
                >
                  删除
                </el-button>
                <el-button
                  type="warning"
                  size="mini"
                  @click="$oucy.go('/enter/publish/good?spuId=' + scope.row.id)"
                >
                  修改
                </el-button>
                <template v-if="scope.row.spuAuditStatus == 2">
                  <el-button
                    type="default"
                    size="mini"
                    @click="clickPutAwaySoldOut(scope.row)"
                    v-if="scope.row.isShelves"
                  >
                    下架
                  </el-button>
                  <el-button
                    type="default"
                    size="mini"
                    @click="clickPutAwaySoldOut(scope.row)"
                    v-else
                  >
                    上架
                  </el-button>
                </template>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div>
          <el-divider></el-divider>
          <div class="df-jc-s-b">
            <div>
              <el-checkbox
                v-model="checked"
                :disabled="!(prlductData && prlductData.content)"
                :indeterminate="isIndeterminate"
                @change="changeChecked"
                >全选</el-checkbox
              >
              <el-button
                class="m-l-10"
                type="primary"
                :disabled="!(multipleSelection && multipleSelection.length)"
                size="mini"
                @click="batch('putAway')"
              >
                批量上架
              </el-button>
              <el-button
                type="primary"
                :disabled="!(multipleSelection && multipleSelection.length)"
                size="mini"
                @click="batch('soldOut')"
              >
                批量下架
              </el-button>
              <el-button
                type="danger"
                :disabled="!(multipleSelection && multipleSelection.length)"
                size="mini"
                @click="batch('deleteSpu')"
              >
                批量删除
              </el-button>
              <el-button
                type="default"
                size="mini"
                @click="$oucy.go('/enter/ProductRecycle')"
              >
                产品回收站
              </el-button>
            </div>
            <el-pagination
              v-if="prlductData"
              background
              layout="sizes, prev, pager, next, jumper, ->, total, slot"
              :page-size="prlductData.size"
              :total="prlductData.totalElements"
              @size-change="sizeChange"
              @current-change="currentChange"
              @prev-click="currentChange"
              @next-click="currentChange"
            ></el-pagination>
          </div>
        </div>
      </div>
    </el-card>
    <el-dialog
      width="500px"
      :title="classifyFormAddAction ? '添加商品分类' : '修改商品分类'"
      :visible.sync="classifyDialogFormVisible"
    >
      <el-form
        :model="classifyForm"
        ref="classifyForm"
        :rules="classifyFormRule"
        label-width="120px"
      >
        <el-form-item label="归类" prop="category">
          <el-radio-group v-model="classifyForm.category">
            <el-radio :label="0">家具类</el-radio>
            <el-radio :label="1">材料类</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="分类名称" prop="name">
          <el-input
            v-model="classifyForm.name"
            autocomplete="off"
            placeholder="请输入分类名称"
            show-word-limit
            maxlength="6"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="keywordFiltr('submitClassifyForm')">
            保 存
          </el-button>
          <el-button @click="classifyDialogFormVisible = false">
            取 消
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      width="800px"
      title="一键导入第三方平台产品"
      :visible.sync="distributionVisible"
    >
      <el-form
        :model="distribution"
        ref="distribution"
        :rules="distributionFormRule"
        label-width="120px"
      >
        <el-form-item label="平台" title=":支持天猫/淘宝/京东/拼多多">
          支持天猫/淘宝/京东/拼多多
          <!--  <el-select v-model="value" disabled placeholder="请选择">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select> -->
        </el-form-item>
        <el-form-item label="排序" v-if="0">
          <el-input-number
            v-model="distribution.spuRankingWeight"
            :min="0"
            :max="10000"
            label="描述文字"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="归类" prop="category">
          <el-radio-group
            v-model="distribution.category"
            @change="changeCategory"
          >
            <el-radio :label="0">家具类</el-radio>
            <el-radio :label="1">材料类</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="分类" prop="classifyId">
          <el-select
            v-model="distribution.classifyId"
            style="width: 340px"
            @change="changeClassify"
          >
            <el-option
              v-for="item in classifyDatas"
              v-if="distribution.category == item.classifyCategory"
              :key="item.id"
              :label="item.classifyName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- ,checkStrictly: true -->
        <el-form-item label="平台分类" prop="globalClassifyIds">
          <el-cascader
            v-model="distribution.globalClassifyIds"
            :options="allGlobalClassifyList"
            :props="{ expandTrigger: 'hover' }"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="产品标签" v-if="0">
          <el-checkbox v-model="distribution.spuIsNew">新品</el-checkbox>
          <el-checkbox v-model="distribution.spuIsSpecial">特价</el-checkbox>
          <el-checkbox v-model="distribution.spuIsRecommend">主推</el-checkbox>
        </el-form-item>
        <template v-if="globalAttrs && globalAttrs.length > 0">
          <div class="my-form-horizontal">
            <template v-for="(item, index) in globalAttrs">
              <template
                v-if="
                  item.furnitureGlobalAttrOption &&
                  item.furnitureGlobalAttrOption.length > 0
                "
              >
                <el-form-item
                  :label="item.attrName"
                  :required="item.attrShouldSelect"
                >
                  <el-select
                    v-model="distribution.globalAttrs[index]"
                    placeholder="请选择"
                    style="width: 340px"
                    @change="item.validate = false"
                  >
                    <el-option
                      v-for="option in item.furnitureGlobalAttrOption"
                      :key="option.id"
                      :label="option.optionValue"
                      :value="option.id"
                    ></el-option>
                  </el-select>
                  <div class="el-form-item__error" v-if="item.validate">
                    请选择{{ item.attrName }}
                  </div>
                </el-form-item>
              </template>
            </template>
          </div>
        </template>
        <el-form-item label="品牌" v-if="0">
          <el-select v-model="distribution.brandId" style="width: 340px">
            <el-option
              v-for="item in brandList"
              :key="item.id"
              :label="item.brandName"
              v-if="item.brandCategory == distribution.category"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="铺货链接" prop="url">
          <el-input
            v-model="distribution.url"
            type="url"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="importFromOther">保 存</el-button>
          <el-button @click="distributionVisible = false">取 消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog
      width="300px"
      title="正在导入商品中"
      :visible.sync="uploadProgressShow"
    >
      <div class="content">
        <div class="progress" style="text-align: center">
          <el-progress type="circle" :percentage="uploadProgress"></el-progress>
        </div>
        <div
          class="text"
          style="display: block; text-align: center; margin-top: 30px"
        >
          {{ uploadProgressDescriber }}
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import oucy from '../../../util/oucyUtil'
import { Notification } from 'element-ui'
import {
  furnitureClassify,
  furnitureGlobalBrand,
  spu,
  globalClassify,
  userAuth,
} from '@/service/index.js'
import { localSet, localGet, localDel } from '@/store/store'
export default {
  name: 'Product',
  data() {
    const validateClassifyName = (rule, value, callback) => {
      const me = this
      if (!value) {
        callback(new Error('请输入分类名称'))
      } else {
        callback()
        /*                    oucy.postRequest('/furnitureClassify/isClassifyNameCanUse',{
                                      id:me.classifyForm.id,
                                      category:me.classifyForm.category,
                                      classifyName: value
                                  }).then(res=>{
                                      if (res===true) {
                                          callback();
                                          console.log(1)
                                      } else {
                                          console.log(2)
                                          callback(new Error('该分类名称已存在'));
                                      }
                                  }).catch(err=>{
                                          console.log(3)
                                      callback(new Error(err));
                                  });*/
      }
    }
    return {
      classifyDialogFormVisible: false,
      classifyDatas: [],
      classifyDatasList: [], //分类处理过的列表
      hasClassifyDatas: true,
      brandList: [],
      hasBrandList: true,
      hasList: true,
      classifyForm: {
        id: null,
        name: null,
        category: 0,
      },
      pageSize: 10,
      pageNow: 0,

      limit: 10,
      start: 0,
      prlductData: {},
      spuName: null,

      // 从第三方平台导入商品
      distributionVisible: !true,
      distribution: {
        // 导入地址
        url: '',
        // 关联分类
        classifyId: '',
        category: 0,
        // 关联品牌
        brandId: '',
        // 排序权重，值越大，排序越靠前
        spuRankingWeight: 0,
        globalAttrs: [],
        // 平台分类
        globalClassifyIds: null,
        globalClassifyId: null,
        spuIsNew: false,
        spuIsSpecial: false,
        spuIsRecommend: false,
      },
      options: [
        {
          value: '1',
          label: '天猫',
        },
        {
          value: '2',
          label: '京东',
        },
      ],
      value: '1',
      enterpriseId: null,
      // 下面是验证的
      classifyFormRule: {
        name: [{ validator: validateClassifyName, trigger: 'blur' }],
        category: [{ required: true, message: '请选择归类', trigger: 'blur' }],
      },
      distributionFormRule: {
        url: [{ required: true, message: '请输入铺货链接', trigger: 'blur' }],
        classifyId: [
          { required: true, message: '请选择分类', trigger: 'blur' },
        ],
        globalClassifyIds: [
          { required: true, message: '请选择平台分类', trigger: 'blur' },
        ],
      },
      classifyFormAddAction: true,
      // 批量操作的数组
      multipleSelection: [],
      globalAttrs: [],
      allGlobalClassifyList: [],
      checked: false,
      isIndeterminate: false,

      // 第三方导入进度
      uploadProgressShow: false,
      uploadProgress: 0,
      uploadProgressDescriber: '准备就绪...',
    }
  },
  mounted() {
    let enterprise = localGet('enterprise')
    const me = this
    if (enterprise && enterprise.id) {
      this.enterpriseId = enterprise.id
    } else {
      this.$oucy.replace('/acc/enterprise')
    }
    furnitureGlobalBrand
      .getUserBrands({ enterpriseId: this.enterpriseId })
      .then((res) => {
        me.brandList = res
        return furnitureClassify.findByLtUserAuthAndClassifyCategory({
          enterpriseId: this.enterpriseId,
        })
      })
      .then((res) => {
        me.classifyDatas = res
        me.setClassifyDatas()
      })

    this.findAllFurnitureSpu()
    this.getAllGlobalClassify()

    // 导入商品
    this.eventBus
      .$off(this.$oucy.msgKeys.SPU_IMPORT)
      .$on(this.$oucy.msgKeys.SPU_IMPORT, (res) => {
        this.uploadProgressShow = true
        this.uploadProgress = res.process
        this.uploadProgressDescriber = res.describer
        if (res.process === 100) {
          this.$message({
            type: 'success',
            message: '导入成功!',
          })
          setTimeout(() => {
            this.uploadProgressShow = false
            res.spu_id &&
              this.$oucy.go('/enter/publish/good?spuId=' + res.spu_id)
          }, 1000)
        }
      })
  },
  methods: {
    keywordFiltr(fn) {
      this.$oucy.keywordFiltr(this.classifyForm).then(
        (res) => {
          this[fn]()
        },
        (err) => {}
      )
    },
    // 查询全局分类树
    getAllGlobalClassify() {
      globalClassify
        .getAllGlobalClassify({
          globalClassifyEnum: this.distribution.category,
        })
        .then((res) => {
          this.setOPtionsValue(res)
          this.allGlobalClassifyList = res
        })
    },
    // 处理多级选择的数据
    setOPtionsValue(list) {
      if (!list) return list
      for (let v of list) {
        v.value = v.id
        v.label = v.classifyName
        if (v.children && v.children.length) {
          this.setOPtionsValue(v.children)
        }
      }
    },
    validateSpuGlbAttrOption() {
      let validate = false
      for (var i = 0; i < this.globalAttrs.length; i++) {
        if (
          !this.globalAttrs[i].attrShouldSelect ||
          this.distribution.globalAttrs[i]
        ) {
          this.globalAttrs[i].validate = false
        } else {
          if (
            this.globalAttrs[i].attrShouldSelect &&
            this.globalAttrs[i].furnitureGlobalAttrOption &&
            this.globalAttrs[i].furnitureGlobalAttrOption.length
          ) {
            validate = true
            this.globalAttrs[i].validate = true
          } else {
            this.globalAttrs[i].validate = !true
          }
        }
      }

      return validate
    },
    // 处理全局属性
    createSpuGlbAttrOption: function () {
      const me = this
      let spuGlbAttrOption = []
      if (
        me.distribution.globalAttrs &&
        me.distribution.globalAttrs.length > 0
      ) {
        me.distribution.globalAttrs.forEach((item) => {
          if (!!item) {
            spuGlbAttrOption.push(item)
          }
        })
      }
      return spuGlbAttrOption.length > 0
        ? JSON.stringify(spuGlbAttrOption)
        : null
    },
    // 切换清空
    changeCategory() {
      this.distribution.classifyId = null
      this.globalAttrs = []
      this.distribution.globalClassifyId = null
      this.distribution.brandId = null
      this.getAllGlobalClassify()
    },
    // 切换触发
    changeClassify() {
      this.queryGlobalAttr()
    },
    // 查询全局属性
    queryGlobalAttr() {
      oucy.queryGlobalAttr(this.distribution.category).then((res) => {
        for (let v of res) {
          v.validate = false
        }
        this.globalAttrs = res
      })
    },
    changeBrand: function (brandId) {
      this.$router.push({
        path: '/enter/addbrand',
        query: {
          code: brandId,
        },
      })
    },
    addClassify: function () {
      console.log('addClassify')
      const me = this
      me.classifyDialogFormVisible = true
      this.classifyFormAddAction = true
      setTimeout(() => {
        this.$refs['classifyForm'].clearValidate()
      })
      me.classifyForm.name = null
      me.classifyForm.category = 0
    },
    editClassify: function (v) {
      console.log('editClassify', v)
      this.classifyForm = {
        id: null,
        name: null,
        category: null,
      }
      const me = this
      this.classifyDialogFormVisible = true
      this.classifyFormAddAction = false
      setTimeout(() => {
        this.$refs['classifyForm'].clearValidate()
      })
      this.classifyForm.id = v.id
      this.classifyForm.name = v.classifyName
      this.classifyForm.category = v.classifyCategory
    },
    submitClassifyForm: function () {
      const me = this
      me.$refs['classifyForm'].validate((valid) => {
        if (valid) {
          // me.classifyDialogFormVisible = false;
          oucy
            .postRequest(
              !!me.classifyFormAddAction
                ? '/client/product/furnitureclassify/addFurnitureClassify'
                : '/client/product/furnitureclassify/updateFurnitureClassify',
              {
                id: me.classifyForm.id,
                classifyName: me.classifyForm.name,
                classifyCategory: me.classifyForm.category,
                enterpriseId: this.enterpriseId,
              }
            )
            .then((res) => {
              me.classifyDialogFormVisible = false
              Notification({
                title: '操作成功',
                message: '分类信息已经提交!',
                type: 'success',
                duration: 4000,
              })
              furnitureClassify
                .findByLtUserAuthAndClassifyCategory({
                  enterpriseId: this.enterpriseId,
                })
                .then((res) => {
                  me.classifyDatas = res
                  me.setClassifyDatas()
                })
              /*oucy.queryUserClassify(null).then(res => {
                          me.classifyDatas = res;
                      });*/
            })
        }
      })
    },
    deleteClassify: function (v) {
      const me = this
      oucy
        .postRequest('/client/product/furnitureclassify/delFurnitureClassify', {
          id: v.id,
          enterpriseId: this.enterpriseId,
        })
        .then((res) => {
          furnitureClassify
            .findByLtUserAuthAndClassifyCategory({
              enterpriseId: this.enterpriseId,
            })
            .then((res) => {
              me.classifyDatas = res
              me.setClassifyDatas()
            })
          me.$message({
            type: 'success',
            message: '删除成功!',
          })
          // me.queryUserAttrTemplate();
        })
    },
    delFurnitureGlobalBrand: function (v, i) {
      const me = this
      oucy
        .postRequest(
          '/client/product/furnitureglobalbrand/delFurnitureGlobalBrand',
          {
            id: v.id,
            enterpriseId: this.enterpriseId,
          }
        )
        .then((res) => {
          me.$message({
            type: 'success',
            message: '删除成功!',
          })
          this.brandList.splice(i, 1)
        })
    },
    delMessageBox(fu, v, text, i) {
      this.$confirm(text || '此操作将永久删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this[fu](v, i)
        })
        .catch((err) => {
          console.log(err)
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },

    // 产品列表
    //
    search() {
      // this.limit=10
      this.start = 0
      this.findAllFurnitureSpu()
    },
    sizeChange: function (limit) {
      const me = this
      me.limit = limit
      me.findAllFurnitureSpu()
    },
    currentChange: function (current) {
      const me = this
      me.start = current - 1
      me.findAllFurnitureSpu()
    },
    findAllFurnitureSpu() {
      // /spu/findAllFurnitureSpuClient
      oucy
        .postRequest('/client/product/spu/findAllFurnitureSpu', {
          spuName: this.spuName,
          limit: this.limit,
          start: this.start,
          enterpriseId: this.enterpriseId,
        })
        .then((res) => {
          console.log(res)
          this.prlductData = res
        })
    },
    // 导入
    importFromOther() {
      // console.log(this.createSpuGlbAttrOption())
      let validate = this.validateSpuGlbAttrOption()
      const me = this
      me.$refs['distribution'].validate((valid) => {
        if (valid) {
          if (validate) return
          // 全局属性
          this.distribution.spuGlbAttrOption = this.createSpuGlbAttrOption()
          // this.distribution.spuIsNew=this.distribution.spuIsNew*1
          // this.distribution.spuIsSpecial=this.distribution.spuIsSpecial*1
          this.distribution.globalClassifyId =
            this.distribution.globalClassifyIds &&
            this.distribution.globalClassifyIds.length
              ? this.distribution.globalClassifyIds[
                  this.distribution.globalClassifyIds.length - 1
                ]
              : null
          console.log(this.distribution)
          // return
          // /spu/findAllFurnitureSpuClient
          oucy
            .postRequest(
              '/client/product/spu/importFromOther',
              this.distribution
            )
            .then((res) => {
              console.log('res: ', res)
              this.distributionVisible = false
              // me.findAllFurnitureSpu();
              if (res) {
                me.$oucy.go('/enter/publish/good?spuId=' + res)
              }

              this.$notify({
                title: '导入成功',
                message: '后台工作人员会尽快审核您的商品',
                type: 'success',
              })
              this.distribution.url = ''
              // me.$message({
              //     type: 'success',
              //     message: '导入成功!'
              // });
            })
        }
      })
    },
    hasImportImg(v) {
      return v && v.startsWith('//')
    },
    // 批量
    batch(type = 'putAway') {
      let spuIds = []
      for (let v of this.multipleSelection) {
        spuIds.push(v.id)
      }
      if (type == 'putAway') {
        this.putAway(spuIds, () => {
          this.findAllFurnitureSpu()
          this.$message({
            type: 'success',
            message: '批量操作成功!',
          })
        })
      } else if (type == 'soldOut') {
        this.soldOut(spuIds, () => {
          this.findAllFurnitureSpu()
          this.$message({
            type: 'success',
            message: '批量操作成功!',
          })
        })
      } else if (type == 'deleteSpu') {
        this.deleteSpu(spuIds, () => {
          this.findAllFurnitureSpu()
          this.$message({
            type: 'success',
            message: '批量操作成功!',
          })
        })
      }
    },
    // 单点一个上架下架
    clickPutAwaySoldOut(v, callback) {
      this[v.isShelves ? 'soldOut' : 'putAway']([v.id], () => {
        v.isShelves = !v.isShelves
        this.$message({
          type: 'success',
          message: '操作成功!',
        })
      })
    },

    // 删除
    deleteFurnitureSpuById(v) {
      spu
        .deleteFurnitureSpuById({
          spuId: v.id,
        })
        .then((res) => {
          this.findAllFurnitureSpu()
        })
    },
    // 上架
    putAway(spuIds, callback) {
      spu
        .putAway({
          spuIds: JSON.stringify(spuIds),
        })
        .then((res) => {
          callback && callback()
        })
    },
    // 上架
    deleteSpu(spuIds, callback) {
      spu
        .deleteSpu({
          spuIds: JSON.stringify(spuIds),
        })
        .then((res) => {
          callback && callback()
        })
    },
    // 下架
    soldOut(spuIds, callback) {
      spu
        .soldOut({
          spuIds: JSON.stringify(spuIds),
        })
        .then((res) => {
          callback && callback()
        })
    },
    // 统一修改接口
    updateSpu(v) {
      spu.updateSpu(v).then(
        (res) => {
          this.$message({
            type: 'success',
            message: '修改成功!',
          })
        },
        (err) => {
          console.error(err)
        }
      )
    },
    // 修改分类
    updateSpuClass(v) {
      this.updateSpu({ spuId: v.id, classifyId: v.classify.id })
    },
    // 修改排序
    blurSpuRankingWeight(v) {
      this.updateSpu({ spuId: v.id, spuRankingWeight: v.spuRankingWeight })
    },
    // 批量选择回调
    handleSelectionChange(val) {
      this.multipleSelection = val
      if (!this.multipleSelection || !this.multipleSelection.length) {
        this.isIndeterminate = false
      }
      if (this.multipleSelection && this.multipleSelection.length) {
        if (
          this.prlductData &&
          this.prlductData.content &&
          this.prlductData.content.length
        ) {
          if (
            this.multipleSelection.length == this.prlductData.content.length
          ) {
            this.checked = true
            this.isIndeterminate = false
          } else {
            this.checked = false
            this.isIndeterminate = true
          }
        } else {
          this.checked = false
          this.isIndeterminate = false
        }
      } else {
        this.checked = false
        this.isIndeterminate = false
      }
    },
    setClassifyDatas() {
      let list = [
        { text: '家具类', id: 0, list: [] },
        { text: '材料类', id: 1, list: [] },
      ]
      for (let v of this.classifyDatas) {
        for (let vv of list) {
          if (v.classifyCategory == vv.id) {
            vv.list.push(v)
          }
        }
      }
      this.classifyDatasList = list
    },
    openNewPage(v) {
      let url = window.location.origin + '/product?spuId=' + v.id
      window.open(url, '_blank')
    },
    changeChecked() {
      console.log('changeChecked')
      if (this.checked) {
        console.log(1)
        this.$refs.productListTable.toggleAllSelection()
      } else {
        console.log(0)
        this.$refs.productListTable.clearSelection()
      }
    },
  },
}
</script>
<style scoped>
.my-header {
  display: flex;
  display: -webkit-flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 20px;
}

.brand-flex-container {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.brand-container {
  width: 200px;
  height: 240px;
  position: relative;
  margin: 0 10px 10px 0;
}

.brand-container-top {
  text-align: center;
}

.brand-container-top img {
  width: 100px;
  height: 100px;
}

.brand-container-top-txt {
  font-size: 10px;
  color: #686869;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.brand-container-button {
  padding: 0px 0 0 0;
  text-align: center;
}

.brand-status {
  position: absolute;
  top: 0;
  right: 0;
}

.spuPrise {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ed2f2f;
}

.putAway,
.soldOut {
  border-radius: 2px;
  font-size: 14px;
  line-height: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  padding: 4px 10px 4px 10px;
  display: inline-block;
}

.putAway {
  background: #eaf9e3;
  color: #69a220;
}

.soldOut {
  background: #f0f0f0;
  color: #c7c7c7;
}

/*分类*/
.classifyDatasListItems {
  border: 1px solid #f2f4f6;
  /*padding: 0 15px;*/
}

.classifyDatasListItem {
  display: flex;
}

.classifyDatasListItem + .classifyDatasListItem {
  border-top: 1px solid #f2f4f6;
}

.classifyDatasListLeft {
  padding: 15px;
  border-right: 1px solid #f2f4f6;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}

.classifyDatasListRight {
  flex: 1;
  padding: 15px 0 0 0;
}
</style>
